import React from "react"

const Clubs = ({ clubs }) => {
  if (!clubs) {
    return null
  }

  return (
    <ul className="card">
      {clubs &&
        clubs.map(club => (
          <li key={club.url}>
            <h5
              dangerouslySetInnerHTML={{
                __html: club.name,
              }}
            />

            <Website url={club.url} />

            <ContactDetails contacts={club.contacts} />
          </li>
        ))}
    </ul>
  )
}

/**
 * Format the URL. Don't display the whole thing but, stil link correctly.
 * Also add some link types for security (together with _blank).
 */
const Website = ({ url }) => {
  if (!url) {
    return null
  }

  const formattedURL = url
    .replace(/^(?:https?:\/\/)?(?:www\.)?/i, "")
    .replace(/\/$/g, "")

  return (
    <div>
      <a
        href={url}
        dangerouslySetInnerHTML={{
          __html: formattedURL,
        }}
        target="_blank"
        rel="nofollow noopener noreferrer"
      />
    </div>
  )
}

const ContactDetails = ({ contacts }) => {
  return (
    <div>
      {contacts.map(contacts => (
        <div key={contacts.contact + contacts?.phone}>
          {contacts.contact ? "Info: " : ""}

          <span
            dangerouslySetInnerHTML={{
              __html: contacts.contact,
            }}
          />
          <div>
            <Phone phone={contacts.phone} />
          </div>
        </div>
      ))}
    </div>
  )
}

/**
 * Format the phone number and make it a link.
 */
const Phone = ({ phone }) => {
  if (!phone) {
    return null
  }

  const url = "tel:" + phone.replace(/\D/g, "").replace(/^0+/g, "+32")

  return (
    <div>
      Tel:{" "}
      <a
        href={url}
        dangerouslySetInnerHTML={{
          __html: phone,
        }}
      />
    </div>
  )
}

export default Clubs
