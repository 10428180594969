import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Clubs from "../components/clubs"

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO title={data?.markdownRemark?.frontmatter?.title || ""} />
      {data && data.markdownRemark.html ? (
        <article>
          <h1
            dangerouslySetInnerHTML={{
              __html: data?.markdownRemark?.frontmatter?.title,
            }}
          />
          <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
        </article>
      ) : null}

      <Clubs clubs={data.markdownRemark.frontmatter.clubs} />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query pageContent($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        path
        templateKey
        title
        clubs {
          url
          name
          contacts {
            contact
            phone
          }
        }
      }
      html
    }
  }
`
